import React from 'react';
import './MainImageSection.css';
import mainImage from '../assets/mountain2.jpg'; // Replace with your main image

function MainImageSection() {
  return (
    <section className="main-image-section">
      <img src={mainImage} alt="Main" className="main-image" />
      <div className="overlay">
        <h1>Notaire à Les Belleville</h1>
        </div>
        <p className='subtitle'>We are at your disposal to advise you and support you in matters of real estate law, family law and business law.</p>
    </section>
  );
}

export default MainImageSection;
