import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import './ContactUs.css';


function ContactUs() {
  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const center = {
        lat: 45.3815361, 
        lng: 6.5024181,
      };
    
    const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="contact-us">
      <div className="contact-content">
        <div className="map-container">
        <header className="contact-header">
        <h2>Visit us</h2>
      </header>
          <LoadScript googleMapsApiKey="AIzaSyBdxs0HQvEL5IDzOzXc4KBCQ53LQnD_AQY">
            {/* <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={14}>
              <Marker position={center} />
            </GoogleMap> */}
            <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={16}>
  <Marker 
    position={center} 
    icon={{ url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" }}
    onClick={() => setIsOpen(true)}  // Opens the info window when the marker is clicked
  />
  
  {isOpen && (
    <InfoWindow position={center} onCloseClick={() => setIsOpen(false)}>
      <div>
        <h4>Notaire - Les Belleville</h4>
        <p>Maître Rabeyrolles</p>
        <p>Address: Les Belleville, France</p>
      </div>
    </InfoWindow>
  )}
</GoogleMap>
          </LoadScript>
        </div>
        <form className="contact-form">
        <header className="contact-header">
        <h2>Contact Us</h2>
      </header>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" placeholder="Your Name" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" placeholder="Your Email" />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address:</label>
            <input type="text" id="address" name="address" placeholder="Your Address" />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" placeholder="Your Message" rows="6"></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </section>
  );
}

export default ContactUs;
