import React from 'react';
import './Quote.css';

function Quote() {
  return (
    <section className="quote-section">
      <div className="quote-container">
        <blockquote>
          <p>"The best way to predict the future is to create it."</p>
          <footer>- Peter Drucker</footer>
        </blockquote>
      </div>
    </section>
  );
}

export default Quote;
