import React from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import MainImageSection from './components/MainImageSection';
import ContentBlocks from './components/ContentBlocks';
import Quote from './components/Quote';
import TeamSection from './components/TeamSection';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <div className="App">
      <Header />
      <MainImageSection />
      <ContentBlocks />
      <Quote />
      <TeamSection />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
