import React from 'react';
import './Footer.css';
import linkedinLogo from '../assets/linkedin-white.png'; // Assuming you have a LinkedIn logo in src/assets/

function Footer() {
  return (
    <footer className="footer">
      <div className="container footer-container">
        <div className="footer-column">
          <h4>Notaire à Les Belleville</h4>
          <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#about">The team</a></li>
            <li><a href="#services">Contact</a></li>
            <li><a href="#services">Careers</a></li>
            <li><a href="#contact">FR</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Contact us</h4>
          <ul>
            <li>Phone : 0623933492</li>
            <li>536 rue de la Duche 73440 LES BELLEVILLE - LES MENUIRES</li>
            <li>We speak English & French</li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Social</h4>
          <ul>
            <li><a href="https://www.linkedin.com/in/muriel-rabeyrolles/"><img src={linkedinLogo} alt="LinkedIn" className="social-icon" /> LinkedIn</a></li>
            {/* Add more social links as needed */}
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Notaire à Les Belleville 2024. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
