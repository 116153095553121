import React from 'react';
import './TeamSection.css';
import teamMember1 from '../assets/team-member1.jpg'; // Replace with actual image paths
import teamMember2 from '../assets/team-member1.jpg';
import teamMember3 from '../assets/team-member1.jpg';
import teamMember4 from '../assets/team-member1.jpg';

function TeamSection() {
  const teamMembers = [
    {
      name: 'John Doe',
      image: teamMember1,
      description: 'Lead Developer with 10 years of experience.'
    },
    {
      name: 'Jane Smith',
      image: teamMember2,
      description: 'Project Manager with a knack for efficiency.'
    },
    {
      name: 'Alice Johnson',
      image: teamMember3,
      description: 'UX Designer with a passion for user-centric design.'
    },
    {
      name: 'Bob Brown',
      image: teamMember4,
      description: 'QA Specialist ensuring top-notch product quality.'
    }
  ];

  return (
    <section className="team-section">
      <h2>Meet Our Team</h2>
      <div className="team-container">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <img src={member.image} alt={member.name} className="team-image" />
            <h3>{member.name}</h3>
            <p>{member.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default TeamSection;
