import React from 'react';
import './ContentBlocks.css';
import image1 from '../assets/expert1.png';
import image2 from '../assets/expert2.png';
import image3 from '../assets/expert3.png';
import image4 from '../assets/expert4.png';

function ContentBlocks() {
  return (
    <div className="main">
    <h2>Our Expertise</h2>
    <section className="content-blocks">
    <div className="block">
          <img src={image1} alt="Real Estate Law" />
          <h3>Real Estate Law</h3>
          <p>Expert advice on real estate transactions and property management.</p>
        </div>
        <div className="block">
          <img src={image2} alt="Family Law" />
          <h3>Family Law</h3>
          <p>Guidance on family-related legal issues and disputes.</p>
        </div>
        <div className="block">
          <img src={image3} alt="Business Law" />
          <h3>Business Law</h3>
          <p>Comprehensive support for business formation and compliance.</p>
        </div>
        <div className="block">
          <img src={image4} alt="International Law" />
          <h3>International Law</h3>
          <p>Assistance with legal matters involving multiple jurisdictions.</p>
        </div>
    </section>
    </div>
  );
}

export default ContentBlocks;
